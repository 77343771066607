import { formatCurrency, formatDate, formatTime } from '@smarttransit/common'

import {
  isAuthorized,
  initBreadcrumbs,
  addAlert
} from '../../../utilities/helpers'

import {
  findPendingPassengers,
  findTotalPendingPassengers,
  findPendingTransferPassengers,
  findTotalPendingTransferPassengers,
  findVerifiedPassengers,
  findTotalVerifiedPassengers,
  findArchivedPassengers,
  findTotalArchivedPassengers
} from '../../../services/passengers-service'

export default {
  props: {
    signedInUser: Object,
    forceRootViewRefresh: Function
  },
  data () {
    return {
      passengers: [],
      breadcrumbs: [],
      totalPassengers: 0,
      totalArchivedPassengers: 0,
      totalPendingPassengers: 0,
      totalPendingTransferPassengers: 0,
      userModalApiInProgress: false,
      apiInProgress: false,
      isArchiveInProgress: false,
      pagination: { sortBy: 'dateUpdated', descending: true },
      selectedDeviceType: null,
      deviceTypes: [{ text: 'Mobile app', value: 'smartphone' }, { text: 'Quickpay', value: 'quickpay' }, { text: 'Shortcode', value: 'ussd' }],
      countries: [],
      selectedCountry: null,
      searchKeywords: '',
      selectedPassengerTab: 'passengers',
      headers: [],
      pendingHeaders: [],
      pendingTransferHeaders: [],
      archivedHeaders: []
    }
  },
  watch: {
    $route () {
      this.breadcrumbs = initBreadcrumbs.bind(this)()
      if (this.$router.currentRoute.name === 'passengers') {
        this.loadFromQueryParams()
        this.onTabChanged(this.selectedPassengerTab)
        this.searchPassengers()
      }
    }
  },
  mounted: function () {
    this.$nextTick(() => {
      this.breadcrumbs = initBreadcrumbs.bind(this)()
    })

    initTableHeaders.bind(this)()

    this.$store.getters.getCountryCurrencies.then((countryCurrencies) => {
      for (const countryId in countryCurrencies) {
        if (countryCurrencies[countryId]) {
          this.countries.push({ text: countryCurrencies[countryId].countryLabel, value: countryId, metadata: countryCurrencies[countryId] })
        }
      }

      if (!this.selectedCountry) {
        this.selectedCountry = this.countries.find((o) => (o.value === 'GHA')).metadata
      }

      this.componentMounted = true

      if (this.$router.currentRoute.name === 'passengers') {
        return this.searchPassengers(null, 1)
      }
    }).catch((err) => {
      console.error(err)
      addAlert({ message: err, type: 'error' })
    })
  },
  methods: {
    loadFromQueryParams () {
      // if (this.$route.query.descending) {
      //   this.pagination.descending = this.$route.query.descending
      // }
      // if (this.$route.query.page) {
      //   this.pagination.page = this.$route.query.page
      // }
    },
    hasUserType (role) {
      const { signedInUser } = this.$props
      return (signedInUser && isAuthorized(signedInUser, role))
    },
    currentUserHasRole (role) {
      const user = this.$store?.state?.credentials?.user || []
      return isAuthorized(user, role)
    },
    resendPendingVerification (passenger) {
      if (confirm(`Confirm re-sending verification for ${passenger.phoneNumber}`)) {
      }
    },
    resendPendingTransferVerification (passenger) {
      if (confirm(`Confirm re-sending transfer verification for ${passenger.phoneNumber}`)) {
      }
    },
    onTabChanged (val) {
      switch (val) {
        case 'archived-passengers':
          this.pagination = { ...this.pagination, sortBy: 'dateArchived', descending: true }
          break
        case 'pending-passengers':
          this.pagination = { ...this.pagination, sortBy: 'dateCreated', descending: true }
          break
        case 'pending-transfer-passengers':
        case 'passengers':
        default:
          this.pagination = { ...this.pagination, sortBy: 'dateUpdated', descending: true }
      }
      // if (this.componentMounted) {
      //   console.warn('tab changed')
      //   this.searchPassengers(val, 1)
      // }
    },
    onPagination () {
      if (this.componentMounted) {
        console.log('on pagination triggered')
        this.searchPassengers(this.selectedPassengerTab)
      }
    },
    onFilterByCountry (val) {
      this.selectedCountry = val ? this.countries.find((o) => (o.value === val)).metadata : null
      this.searchPassengers(this.selectedPassengerTab, 1)
    },
    onFilterByDeviceType (val) {
      this.selectedDeviceType = val
      this.searchPassengers(this.selectedPassengerTab, 1)
    },
    loadArchivePassenger (passenger) {
      this.$router.push({ name: 'passengers-passenger', params: { passengerId: passenger.id }, query: { archive: '1' } })
    },
    loadUnarchivePassenger (passenger) {
      this.$router.push({ name: 'passengers-passenger', params: { passengerId: passenger.id }, query: { unarchive: '1' } })
    },
    getTotal (category) {
      if (category === 'pending-passengers') {
        return this.totalPendingPassengers
      } else if (category === 'pending-transfer-passengers') {
        return this.totalPendingTransferPassengers
      } else if (category === 'archived-passengers') {
        return this.totalArchivedPassengers
      } else {
        return this.totalPassengers
      }
    },
    /**
     *
     * @param {string} category - can be 'invited' or 'archived'
     * @param {number} setPage - force the page to be this over whatever pagination has
     * @returns {Promise<void>}
     */
    async searchPassengers (category = undefined, setPage) {
      let order
      let promises = []
      this.apiInProgress = true
      const { sortBy, descending, page, rowsPerPage } = this.pagination
      const offset = (setPage || page) === 1 ? 0 : ((setPage || page) * rowsPerPage) - rowsPerPage
      const limit = rowsPerPage

      if (sortBy) {
        if (sortBy === 'dateUpdated') {
          order = [`dateUpdated ${descending ? 'DESC NULLS LAST' : 'ASC'}`, `dateCreated ${descending ? 'DESC' : 'ASC'}`]
        } else {
          order = `${sortBy} ${descending ? 'DESC' : 'ASC'}`
        }
      }

      category = category || this.selectedPassengerTab

      if (category === 'pending-passengers') {
        promises.push(findPendingPassengers({
          keywords: String(this.searchKeywords).trim(),
          identifierType: this.selectedDeviceType,
          countryId: this.selectedCountry && this.selectedCountry.countryId,
          limit,
          offset,
          order
        }))

        const totalPendingCountPromise = findTotalPendingPassengers({
          keywords: String(this.searchKeywords).trim(),
          identifierType: this.selectedDeviceType,
          countryId: this.selectedCountry && this.selectedCountry.countryId
        })

        totalPendingCountPromise.then((result) => {
          this.totalPendingPassengers = result.count
        })

        promises.push(totalPendingCountPromise)
      } else if (category === 'pending-transfer-passengers') {
        console.warn('in category:', category)
        promises.push(findPendingTransferPassengers({
          keywords: String(this.searchKeywords).trim(),
          identifierType: this.selectedDeviceType,
          countryId: this.selectedCountry && this.selectedCountry.countryId,
          limit,
          offset,
          order
        }))

        const totalPendingTransferCountPromise = findTotalPendingTransferPassengers({
          keywords: String(this.searchKeywords).trim(),
          identifierType: this.selectedDeviceType,
          countryId: this.selectedCountry && this.selectedCountry.countryId
        })

        totalPendingTransferCountPromise.then((result) => {
          this.totalPendingTransferPassengers = result.count
        })

        promises.push(totalPendingTransferCountPromise)
      } else if (category === 'archived-passengers') {
        promises.push(findArchivedPassengers({
          keywords: String(this.searchKeywords).trim(),
          identifierType: this.selectedDeviceType,
          countryId: this.selectedCountry && this.selectedCountry.countryId,
          limit,
          offset,
          order
        }))

        const totalArchivedCountPromise = findTotalArchivedPassengers({
          keywords: String(this.searchKeywords).trim(),
          identifierType: this.selectedDeviceType,
          countryId: this.selectedCountry && this.selectedCountry.countryId
        })

        totalArchivedCountPromise.then((result) => {
          this.totalArchivedPassengers = result.count
        })

        promises.push(totalArchivedCountPromise)
      } else {
        promises.push(findVerifiedPassengers({
          keywords: String(this.searchKeywords).trim(),
          identifierType: this.selectedDeviceType,
          countryId: this.selectedCountry && this.selectedCountry.countryId,
          limit,
          offset,
          order
        }))

        const totalVerifiedCountPromise = findTotalVerifiedPassengers({
          keywords: String(this.searchKeywords).trim(),
          identifierType: this.selectedDeviceType,
          countryId: this.selectedCountry && this.selectedCountry.countryId
        })

        totalVerifiedCountPromise.then((result) => {
          this.totalPassengers = result.count
        })

        promises.push(totalVerifiedCountPromise)
      }

      const [results] = await Promise.all(promises)

      this.passengers = results.map((o) => {
        return this.setPassenger({ passenger: o, countries: this.countries, selectedCountry: this.selectedCountry, deviceTypes: this.deviceTypes })
      })

      this.apiInProgress = false
    },
    setPassenger ({ passenger, countries, selectedCountry, deviceTypes }) {
      const newObj = { ...passenger }
      selectedCountry = selectedCountry || (countries.find((o) => (o.value === (newObj.currentCountryIdDetected || newObj.currentCountryIdSet))) || {}).metadata

      newObj.balanceLabel = `${selectedCountry ? selectedCountry.currencySymbol : ''}${formatCurrency(newObj.balance)}`
      newObj.passengerIdentifier = newObj.passengerIdentifiers && newObj.passengerIdentifiers.length ? newObj.passengerIdentifiers[0] : null

      if (newObj.passengerIdentifier) {
        newObj.isExpired = (newObj.passengerIdentifier.activationCodeExpiry && new Date(newObj.passengerIdentifier.activationCodeExpiry).getTime() <= Date.now()) ||
          (newObj.passengerIdentifier.transferCodeExpiry && new Date(newObj.passengerIdentifier.transferCodeExpiry).getTime() <= Date.now())

        newObj.activationCodeExpiryLabel = newObj.passengerIdentifier.activationCodeExpiry ? `${formatTime(newObj.passengerIdentifier.activationCodeExpiry)}, ${formatDate(newObj.passengerIdentifier.activationCodeExpiry)}` : ''
        newObj.transferCodeExpiryLabel = newObj.passengerIdentifier.transferCodeExpiry ? `${formatTime(newObj.passengerIdentifier.transferCodeExpiry)}, ${formatDate(newObj.passengerIdentifier.transferCodeExpiry)}` : ''
        newObj.passengerIdentifier.dateCreatedLabel = newObj.passengerIdentifier.dateCreated ? `${formatTime(newObj.passengerIdentifier.dateCreated)}, ${formatDate(newObj.passengerIdentifier.dateCreated)}` : ''
        newObj.passengerIdentifier.dateUpdatedLabel = newObj.passengerIdentifier.dateUpdated ? `${formatTime(newObj.passengerIdentifier.dateUpdated)}, ${formatDate(newObj.passengerIdentifier.dateUpdated)}` : ''

        if (newObj.passengerIdentifier.osVersion && newObj.passengerIdentifier.osVersion.indexOf('{') > -1 && newObj.passengerIdentifier.osVersion.indexOf('}') > -1) {
          try {
            newObj.passengerIdentifier.osVersionLabel = JSON.stringify(JSON.parse(newObj.passengerIdentifier.osVersion), null, 2)
          } catch (error) {
            newObj.passengerIdentifier.osVersionLabel = newObj.passengerIdentifier.osVersion.replace(/,[\s]*/g, ',\n')
          }
        } else {
          newObj.passengerIdentifier.osVersionLabel = newObj.passengerIdentifier.osVersion ? newObj.passengerIdentifier.osVersion.replace(/,[\s]*/g, ',\n') : 'N/A'
        }
      }

      newObj.passengerTrip = newObj.passengerTrips && newObj.passengerTrips.length ? newObj.passengerTrips[0] : null

      if (newObj.passengerTrip) {
        newObj.passengerTrip.dateUpdatedLabel = newObj.passengerTrip.dateUpdated ? `${formatTime(newObj.passengerTrip.dateUpdated)}, ${formatDate(newObj.passengerTrip.dateUpdated)}` : ''
      }

      newObj.passengerAlternateIdentifier = newObj.passengerAlternateIdentifiers && newObj.passengerAlternateIdentifiers.length ? newObj.passengerAlternateIdentifiers[0] : null

      if (newObj.passengerAlternateIdentifier) {
        newObj.passengerAlternateIdentifier.dateCreatedLabel = newObj.passengerAlternateIdentifier.dateUpdated ? `${formatTime(newObj.passengerAlternateIdentifier.dateCreated)}, ${formatDate(newObj.passengerAlternateIdentifier.dateCreated)}` : ''
        newObj.passengerAlternateIdentifier.dateUpdatedLabel = newObj.passengerAlternateIdentifier.dateCreated ? `${formatTime(newObj.passengerAlternateIdentifier.dateUpdated)}, ${formatDate(newObj.passengerAlternateIdentifier.dateUpdated)}` : ''
        newObj.passengerAlternateIdentifier.dateArchivedLabel = newObj.passengerAlternateIdentifier.dateArchived ? `${formatTime(newObj.passengerAlternateIdentifier.dateArchived)}, ${formatDate(newObj.passengerAlternateIdentifier.dateArchived)}` : ''
      }

      newObj.lastAccessType = newObj.passengerIdentifier ? (deviceTypes.find((o) => (newObj.passengerIdentifier.identifierType === o.value)) || {}).text : ''
      newObj.lastKnownTripLabel = newObj.passengerTrip ? `${newObj.passengerTrip.originLabel} ↔ ${newObj.passengerTrip.destinationLabel}` : ''
      newObj.dateArchivedLabel = newObj.dateArchived ? `${formatTime(newObj.dateArchived)}, ${formatDate(newObj.dateArchived)}` : ''
      newObj.dateCreatedLabel = `${formatTime(newObj.dateCreated)}, ${formatDate(newObj.dateCreated)}`
      newObj.dateUpdatedLabel = newObj.dateUpdated ? `${formatTime(newObj.dateUpdated)}, ${formatDate(newObj.dateUpdated)}` : ''
      newObj.currentCountryIdDetectedLabel = newObj.currentCountryIdDetected ? (countries.find((o) => (o.value === newObj.currentCountryIdDetected)) || {}).text : ''
      newObj.currentCountryIdDetectedLabel = newObj.currentCountryIdDetectedLabel || 'N/A'
      newObj.currentCountryIdSetLabel = newObj.currentCountryIdSet ? (countries.find((o) => (o.value === newObj.currentCountryIdSet)) || {}).text : ''
      newObj.currentCountryIdSetLabel = newObj.currentCountryIdSetLabel || 'N/A'

      return newObj
    },
    loadPassenger (passengerId) {
      this.$router.push({ name: 'passengers-passenger', params: { passengerId } })
    }
  }
}

function initTableHeaders () {
  this.headers = [
    {
      text: 'Phone',
      align: 'left',
      sortable: false
    },
    {
      text: 'Balance',
      align: 'left',
      sortable: true,
      value: 'balance'
    },
    {
      text: 'Last Access Type',
      align: 'left',
      sortable: false
    },
    {
      text: 'Last Known Trip',
      align: 'left',
      sortable: false
    },
    {
      text: 'Date Created',
      align: 'left',
      sortable: true,
      value: 'dateCreated'
    },
    {
      text: 'Date Updated',
      align: 'left',
      sortable: true,
      value: 'dateUpdated'
    },
    { text: 'Actions', sortable: false }
  ]

  this.pendingHeaders = [
    {
      text: 'Phone',
      align: 'left',
      sortable: false,
      value: 'phoneNumber'
    },
    {
      text: 'Last Access Type',
      align: 'left',
      sortable: false,
      value: 'identifierType'
    },
    {
      text: 'Date Created',
      align: 'left',
      sortable: true,
      value: 'dateCreated'
    },
    {
      text: 'Activation Expiry Date',
      align: 'left',
      sortable: true,
      value: 'activationCodeExpiry'
    },
    { text: 'Actions', sortable: false }
  ]

  this.pendingTransferHeaders = [
    {
      text: 'Phone',
      align: 'left',
      sortable: false,
      value: 'phoneNumber'
    },
    {
      text: 'Last Access Type',
      align: 'left',
      sortable: false,
      value: 'identifierType'
    },
    {
      text: 'Pending Transfer Expiry Date',
      align: 'left',
      sortable: true,
      value: 'transferCodeExpiry'
    },
    {
      text: 'Date Updated',
      align: 'left',
      sortable: true,
      value: 'dateUpdated'
    },
    { text: 'Actions', sortable: false }
  ]

  this.archivedHeaders = [
    {
      text: 'Phone',
      align: 'left',
      sortable: false,
      value: 'phoneNumber'
    },
    {
      text: 'Balance',
      align: 'left',
      sortable: true,
      value: 'balance'
    },
    {
      text: 'Last Access Type',
      align: 'left',
      sortable: false,
      value: 'identifierType'
    },
    {
      text: 'Last Known Trip',
      align: 'left',
      sortable: false,
      value: 'lastKnownTrip'
    },
    {
      text: 'Date Created',
      align: 'left',
      sortable: true,
      value: 'dateCreated'
    },
    {
      text: 'Date Updated',
      align: 'left',
      sortable: true,
      value: 'dateUpdated'
    },
    {
      text: 'Date Archived',
      align: 'left',
      sortable: true,
      value: 'dateArchived'
    },
    { text: 'Actions', sortable: false }
  ]
}
